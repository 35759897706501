<template>
<div>
  <Header/>
  <div class="row m-auto">
    <div class="col-12 col-md-11 banner m-auto px-0 d-flex justify-content-center">

       
       <router-link class=" m-auto" to="/made-to-size">
          <img class="animation" :src="homeContent.banner.file">
        </router-link>     
    </div>
</div>
<div class="mx-auto col-12 col-md-10 overflow-hidden px-0">
  <div class="row h-25 blue-bottom">
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12" >
       <div class="row ml-3">
        <div class="col-11 px-1">
          <img src="@/assets/estrella.svg"  class="mb-0 workshop-title-icon mb-4" />
          <span class="subtitle-text red-text"> {{homeContent.leftTitle}}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12" >
     <div class="row ml-3">
      <div class="col-11 px-1">
        <div class="text-center d-none d-md-block ">
          <router-link to="/boom-tools">
            <img src="@/assets/estrella-roja.svg" class="red-star-home pb-4" />
            <span class="blue-title mx-1">{{homeContent.rightTitle}}</span>
            <img src="@/assets/estrella-roja.svg" class="red-star-home pb-4" />
          </router-link>
        </div>
      </div>
       </div>
     </div>
   </div>
  <div class="row h-25">
    <div class="col-12 col-md-6 px-0">
      <div class="row">
        <div class="col-4 px-5 px-md-3 text-center">
                <img class=" mt-2 ml-3" src="@/assets/badge-home.svg" />
        </div>
         <div class="col-7 col-md-8 pl-0">
            <p class="paragraph-title">{{homeContent.leftSubTitle}}</p>
            <p class="home-text text-justify">{{homeContent.leftText}}</p>
          </div>
      </div>
      <div class="row contact-buttons d-block">
       
          <div class="m-auto justify-content-right d-none d-md-block contact-button">
                    <button v-on:click="showModal=true" class="contact-us-button bottom-right">{{homeContent.contactButton}} 
                      <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill>
                    </button>
            </div>

              <div class="m-auto d-flex justify-content-center justify-content-right d-md-none d-block">
                    <button v-on:click="showModal=true" class="contact-us-button my-5">{{homeContent.contactButton}}
                      <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill>
                    </button>
              </div>
        
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="text-center d-md-none d-block">
        <router-link to="/boom-tools">
                    <img src="@/assets/estrella-roja.svg" class="red-star-home pb-4" />
                    <span class="blue-title mx-1">{{homeContent.rightTitle}}</span>
                    <img src="@/assets/estrella-roja.svg" class="red-star-home pb-4" />
        </router-link>
                </div>
      <div class="text-center">
        <div class="clickable" v-on:click="showImageModal=true; showModal=true">
          <img class="home-image hover-image mx-auto" :src="homeContent.rightImage.file">
        </div>
      </div>
    </div>
  </div>

     
     
      
        <div class="row mt-4 h-25">
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12" >
             <div class="row ml-3">
              <div class="col-11 px-1">
                <img src="@/assets/estrella.svg"  class="mb-0 workshop-title-icon mb-4" />
                <span class="subtitle-text red-text"> {{homeContent.lowerTitle}}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
              <div class="blue-separator"> </div>
          </div>
        </div>

        <div class="row mt-4 mb-3 h-25 flex red-bottom">
          <carousel class="col-12" paginationColor="#007bff" :perPageCustom="[[480, 2], [768, 6]]">
            <slide class="" :key="key" v-for="(workshop,key) in workshops">
              <router-link class="home-badge"  :to="{ name: 'workshop', params: { id: workshop.Id }}">
                <div class="px-2"> 
                <img :src="getImage(key)" class="hover-image w-100" />
                </div>
              </router-link>
            </slide>
          </carousel>
        </div>

</div>


<vue-final-modal v-model="showModal" :overlay-style="overlayStyle">
   
       <div class="row mt-2 mb-5 mb-md-0"> 
        <div class="col-9">
        </div>
        <div class="col-2">
            <div v-if="!formSuccess" v-on:click="showModal=false; showImageModal=false" class="clickable"> 
                <img src="@/assets/tache.svg" class="remove-x">
            </div>
        </div>
    </div>
    <div v-if="!showImageModal && !formSuccess" class="row" >
        <div class=" col-10  mx-auto modal__content" >
            <div  class="text-center">
            <span class="red-title red-text m-auto"> Contact Us</span><br>
            </div>
            <div class="row mt-0">
                <b-form class="col-12 col-lg-7 m-auto" >
               
                

                <div class="form-group">
                    <div class="row blue-form">
                <div for="name" class="col-4 form-label">*Name:</div>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <div for="email" class="col-4 form-label">*Email:</div>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                         <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-lg-4">Comments:</label>
                        <div class="col-lg-7">
                            <textarea v-model="form.comments"  
                            rows="4" 
                            class="form-control blue-form comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold; font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;"
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-7 form-check d-flex justify-content-center">
                        <div class="align-self-center">
                        <input type="checkbox" v-model="form.privacyPolicy" value=true class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1">
                             <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>
                        </label>
                     </div>
                </div>

                 <div class="col-5 form-check px-0 d-flex justify-content-end">
                
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="bg-red contact-us-button" for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
            </div>
            
        </div>
    </div>    
    <div class="row mt-5 mt-md-0" v-if="showImageModal">
        <div class="mx-auto col-md-6 col-12 mt-n5 text-center">
          <img class="modal-image" :src="homeContent.rightImage.file" >
        </div>
    </div>
     <div v-if="formSuccess" class="row">
       <div class="col-10 col-md-4 mx-auto ">
          <div  class=" contact-us-button text-center mt-50">
            Format sent!
          </div>
       </div>
     </div>
</vue-final-modal>


  <Footer/>
</div>
</template>

<script>
// S E R V I C E S




import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { Carousel, Slide } from 'vue-carousel'
import axios from 'axios'
import WorkshopService from '@/service/workshop.js'
import HomeService from '@/service/home.js'
import {  VueFinalModal } from 'vue-final-modal'

import EmailService from "@/service/email"


  export default {
     components: {
       Header,
       Footer,
       Carousel,
       Slide,
       VueFinalModal
  },

  watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            this.formSuccess = false;
            this.showModal = false;
          }, 3000);
        }
      }
    },
  computed:{

    isFormfilled(){
        if(this.form.name !="" &&
            this.form.email !="" &&
            this.form.comments !="" &&
            this.form.privacyPolicy
            ){     
                return false;
            }
            return true;

    },

        disabledClass(){
        if(this.isFormfilled){
            return "disabled-button";
        }
        return "clickable";
    }

    },
    data() {
      return {
         src:'/banner_normal/index.html',
        imagePath:'/img/',
        workshopsPath: '/img/workshops',
        animation:'',
        formSuccess:false,
        workshops:[],
        showModal:false,
        showImageModal:false,
        form:{
        name:"",
        email:"",
        comments:"",
        privacyPolicy:false,
        subject:"Home"
        },
         homeContent:{
            banner:{
              file:'',
              name:''},
            leftTitle:'',
            leftSubTitle:'',
            leftText:'',
            contactButton:'',
            rightTitle:'',
            rightImage:{
              file:'',
              name:''
            },
            lowerTitle:'',
          },
        isLoged:false,
         overlayStyle:{
            background:"#FFFFFF",
            opacity:0.9
        },
      }
    },

    mounted(){
      window.scrollTo(0,0);
      this.checkLogin();
      this.getBanner();
      this.getWorkshops();
      this.getHomeContent();
    },

    methods:{
         getHomeContent(){
      HomeService.getHomeContent()
        .then(res => { 
            this.homeContent.banner.file = this.imagePath+res.data.banner;
            this.homeContent.banner.name = res.data.banner.split("/");
            this.homeContent.banner.name = this.homeContent.banner.name.slice(-1)[0];
            this.homeContent.leftTitle = res.data.leftTitle;
            this.homeContent.leftSubTitle = res.data.leftSubTitle;
            this.homeContent.leftText = res.data.leftText;
            this.homeContent.contactButton = res.data.contactButton;
            this.homeContent.rightTitle = res.data.rightTitle;
            this.homeContent.rightImage.file = this.imagePath+res.data.rightImage;
            this.homeContent.rightImage.name = res.data.rightImage.split("/");
            this.homeContent.rightImage.name = this.homeContent.rightImage.name.slice(-1)[0];
            this.homeContent.lowerTitle = res.data.lowerTitle;

            console.log(res)
        });

    },

    send(){
        this.form.selectedGoals = this.selectedGoals;
          EmailService.sendEmail(this.form).then(()=>{
            this.form={};
            this.formSuccess = true;
          }).catch(()=>{
            console.log('error')
          })
      },


    getWorkshops(){
      WorkshopService.getWorkshops()
        .then(res => { 
            this.workshops = res.data;
        });

    },
  
    getBanner(){

    return axios({
        method: 'get',
        url: '/banner_normal/index.html'})
    .then(res => { 
        this.animation = res.data;
        
    });
      },
      checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },


      logout(){
        sessionStorage.clear();
        this.isLoged = false;
      },

       getImage(key){
           return this.workshops[key].Image;
           //return require('/acierto.svg');

      }
      
    }
  }
</script>

